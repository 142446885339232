export const country_region = [
	{ value: "en_US", label: "United States - angielski" },
  { value: "es_AR", label: "Argentina - hiszpański" },
	{ value: "en_AW", label: "Aruba - angielski" },
	{ value: "en_AU", label: "Australia - angielski" },
	{ value: "de_AT", label: "Austria - niemiecki" },
	{ value: "en_BS", label: "Bahamas - angielski" },
	{ value: "en_BD", label: "Bangladesz - angielski" },
	{ value: "en_BB", label: "Barbados - angielski" },
	{ value: "nl_BE", label: "Belgium - holenderski" },
	{ value: "fr_BE", label: "Belgium - francuski" },
	{ value: "es_BO", label: "Bolivia - hiszpański" },
	{ value: "pt_BR", label: "Brazil - portugalski" },
	{ value: "en_BG", label: "Bułgaria - angielski" },
	{ value: "en_CA", label: "Canada - angielski" },
	{ value: "fr_CA", label: "Canada - francuski" },
	{ value: "es_CL", label: "Chile - hiszpański" },
	{ value: "zh_CN", label: "China - chiński uproszczony" },
	{ value: "es_CO", label: "Colombia - hiszpański" },
	{ value: "es_CR", label: "Costa Rica - hiszpański" },
	{ value: "cs_CZ", label: "Czechy - czeski" },
	{ value: "da_DK", label: "Denmark - duński" },
	{ value: "es_DO", label: "Dominican Republic - hiszpański" },
	{ value: "es_EC", label: "Ecuador - hiszpański" },
	{ value: "en_EG", label: "Egypt - angielski" },
	{ value: "es_SV", label: "El Salvador - hiszpański" },
    { value: "en_EE", label: "Estonia - angielski"},
	{ value: "fi_FI", label: "Finlandia - fiński" },
	{ value: "fr_FR", label: "France - francuski" },
	{ value: "de_DE", label: "Germany - niemiecki" },
	{ value: "el_GR", label: "Grecja - grecki" },
	{ value: "en_GD", label: "Grenada - angielski" },
	{ value: "es_GT", label: "Guatemala - hiszpański" },
	{ value: "es_HN", label: "Honduras - hiszpański" },
	{ value: "en_HK", label: "Hong Kong - angielski" },
	{ value: "zh_HK", label: "Hong Kong - chiński tradycyjny" },
	{ value: "en_IN", label: "India - angielski" },
	{ value: "en_ID", label: "Indonesia - angielski" },
	{ value: "in_ID", label: "Indonesia - indonezyjski" },
	{ value: "en_IE", label: "Ireland - angielski" },
	{ value: "en_IL", label: "Israel - angielski" },
	{ value: "it_IT", label: "Italy - włoski" },
	{ value: "en_JM", label: "Jamaica - angielski" },
	{ value: "ja_JP", label: "Japan - japoński" },
	{ value: "ko_KR", label: "Korea - koreański" },
	{ value: "en_KW", label: "Kuwait - angielski" },
    { value: "en_LV", label: "Łotwa - angielski"},
	{ value: "en_LB", label: "Liban - angielski" },
    { value: "en_LT", label: "Litwa - angielski"},
	{ value: "fr_LU", label: "Luxembourg - francuski" },
	{ value: "en_MY", label: "Malaysia - angielski" },
	{ value: "es_MX", label: "Mexico - hiszpański" },
	{ value: "en_MA", label: "Morocco - angielski" },
	{ value: "nl_NL", label: "Netherlands - holenderski" },
	{ value: "en_NZ", label: "New Zealand - angielski" },
	{ value: "es_NI", label: "Nicaragua - hiszpański" },
	{ value: "no_NO", label: "Norway - norweski" },
	{ value: "en_PK", label: "Pakistan - angielski" },
	{ value: "es_PA", label: "Panama - hiszpański" },
	{ value: "es_PY", label: "Paraguay - hiszpański" },
	{ value: "es_PE", label: "Peru - hiszpański" },
	{ value: "en_PH", label: "Philippines - angielski" },
	{ value: "pl_PL", label: "Poland - polski" },
	{ value: "pt_PT", label: "Portugal - portugalski" },
	{ value: "es_PR", label: "Puerto Rico - hiszpański" },
	{ value: "en_RO", label: "Rumunia - angielski" },
	{ value: "ru_RU", label: "Russia Federation - rosyjski" },
	{ value: "en_SA", label: "Saudi Arabia - angielski" },
	{ value: "en_SG", label: "Singapore - angielski" },
	{ value: "es_ES", label: "Spain - hiszpański" },
	{ value: "en_LK", label: "Sri Lanka - angielski" },
	{ value: "en_ZA", label: "South Africa - angielski" },
    { value: "en_SK", label: "Słowacja - angielski"},
	{ value: "en_SI", label: "Słowenia - angielski" },
	{ value: "sv_SE", label: "Sweden - szwedzki" },
	{ value: "fr_CH", label: "Switzerland - francuski" },
	{ value: "de_CH", label: "Switzerland - niemiecki" },
	{ value: "zh_TW", label: "Taiwan - chiński tradycyjny" },
	{ value: "en_TH", label: "Thailand - angielski" },
	{ value: "th_TH", label: "Thailand - tajski" },
	{ value: "en_TT", label: "Trinidad and Tobago - angielski" },
	{ value: "tr_TR", label: "Turkey - turecki" },
	{ value: "en_AE", label: "United Arab Emirates - angielski" },
	{ value: "en_GB", label: "United Kingdom - angielski" },
	{ value: "es_UY", label: "Uruguay - hiszpański" },
	{ value: "es_VE", label: "Venezuela - hiszpański" },
	{ value: "en_VN", label: "Vietnam - angielski" },
	{ value: "hu_HU", label: "Węgry - węgierski" },

]

export const primaryUse = [
  { value: "", label: "Wybierz jeden" },
	{ value: "002", label: "Użytek osobisty" },
	{ value: "003", label: "Firma w domu" },
	{ value: "005", label: "Firma zatrudniająca 9 lub mniej pracowników" },
	{ value: "006", label: "Firma zatrudniająca 10 lub więcej pracowników" },
]

export const salutation = [
  { value: "", label: "Wybierz jeden" },
	{ value: "001", label: "Pan" },
	{ value: "003", label: "Pani" },
]

export const jobResponsibility = [
  { value: "", label: "Wybierz jedną" },
	{ value: "041", label: "Asystent administracyjny" },
	{ value: "030", label: "Specjalista" },
	{ value: "035", label: "Specjalista w dziedzinie CAD/CAM" },
	{ value: "033", label: "Właściciel firmy/dyrektor naczelny" },
	{ value: "055", label: "Konsultant/wdrożeniowiec systemów" },
	{ value: "034", label: "Kierownik wydziału" },
	{ value: "046", label: "Inżynier/technik" },
	{ value: "054", label: "Członek kierownictwa/zarządu" },
	{ value: "036", label: "Kierownik obiektu" },
	{ value: "047", label: "Specjalista ds. finansów/księgowości" },
	{ value: "031", label: "Dyrektor finansowy" },
	{ value: "037", label: "Grafik" },
	{ value: "032", label: "Członek kierownictwa ds. informatyki (CIO/CTO)" },
	{ value: "051", label: "Strateg ds. informatyki" },
	{ value: "049", label: "Specjalista ds. marketingu" },
	{ value: "045", label: "Kierownik działu informatycznego/systemów informatycznych" },
	{ value: "044", label: "Specjalista ds. systemów informatycznych/informatyk" },
	{ value: "042", label: "Kierownik biura" },
	{ value: "043", label: "Specjalista ds. zakupów/zaopatrzenia" },
	{ value: "050", label: "Specjalista ds. sprzedaży" },
	{ value: "040", label: "Serwis/wsparcie/pomoc techniczna" },
	{ value: "048", label: "Programista" },
	{ value: "052", label: "Kierownik/dyrektor szkoły" },
	{ value: "053", label: "Nauczyciel" },
	{ value: "000", label: "Inny" },
]
