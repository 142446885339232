export const country_region = [
	{ value: "en_US", label: "Internationaal - Nederlands" },
  { value: "es_AR", label: "Argentinië - Spaans" },
	{ value: "en_AW", label: "Aruba - Engels" },
	{ value: "en_AU", label: "Australië - Engels" },
	{ value: "en_BS", label: "Bahamas - Engels" },
	{ value: "en_BB", label: "Barbados - Engels" },
	{ value: "en_BD", label: "Bangladesh - Engels" },
	{ value: "nl_BE", label: "België - Nederlands" },
	{ value: "fr_BE", label: "België - Frans" },
	{ value: "es_BO", label: "Bolivia - Spaans" },
	{ value: "pt_BR", label: "Brazilië - Portugees" },
	{ value: "en_BG", label: "Bulgarije - Engels" },
	{ value: "en_CA", label: "Canada - Engels" },
	{ value: "fr_CA", label: "Canada - Frans" },
	{ value: "es_CL", label: "Chili - Spaans" },
	{ value: "zh_CN", label: "China - Vereenvoudigd Chinees" },
	{ value: "es_CO", label: "Colombia - Spaans" },
	{ value: "es_CR", label: "Costa Rica - Spaans" },
	{ value: "da_DK", label: "Denemarken - Deens" },
	{ value: "es_DO", label: "Dominicaanse Republiek - Spaans" },
	{ value: "de_DE", label: "Duitsland - Duits" },
	{ value: "es_EC", label: "Ecuador - Spaans" },
	{ value: "en_EG", label: "Egypte - Engels" },
	{ value: "es_SV", label: "El Salvador - Spaans" },
    { value: "en_EE", label: "Estland - Engels"},
	{ value: "en_PH", label: "Filippijnen - Engels" },
	{ value: "fi_FI", label: "Finland - Fins" },
	{ value: "fr_FR", label: "Frankrijk - Frans" },
	{ value: "el_GR", label: "Griekenland - Grieks" },
	{ value: "en_GD", label: "Grenada - Engels" },
	{ value: "es_GT", label: "Guatemala - Spaans" },
	{ value: "es_HN", label: "Honduras - Spaans" },
	{ value: "en_HK", label: "Hong Kong - Engels" },
	{ value: "zh_HK", label: "Hong Kong - Traditioneel Chinees" },
	{ value: "hu_HU", label: "Hongarije - Hongaars" },
	{ value: "en_IN", label: "India - Engels" },
	{ value: "en_ID", label: "Indonesië - Engels" },
	{ value: "in_ID", label: "Indonesië - Indonesische" },
	{ value: "en_IE", label: "Ierland - Engels" },
	{ value: "en_IL", label: "Israël - Engels" },
	{ value: "it_IT", label: "Italië - Italiaans" },
	{ value: "en_JM", label: "Jamaica - Engels" },
	{ value: "ja_JP", label: "Japan - Japans" },
	{ value: "ko_KR", label: "Korea - Koreaans" },
	{ value: "en_KW", label: "Koeweit - Engels" },
    { value: "en_LV", label: "Letland - Engels"},
	{ value: "en_LB", label: "Libanon - Engels" },
    { value: "en_LT", label: "Litouwen - Engels"},
	{ value: "fr_LU", label: "Luxemburg - Frans" },
	{ value: "en_MY", label: "Maleisië - Engels" },
	{ value: "es_MX", label: "Mexico - Spaans" },
	{ value: "en_MA", label: "Marokko - Engels" },
	{ value: "en_GB", label: "Nederland - Nederlands" },
	{ value: "nl_NL", label: "Nederland - Nederlands" },
	{ value: "en_NZ", label: "Nieuw Zeeland - Engels" },
	{ value: "es_NI", label: "Nicaragua - Spaans" },
	{ value: "no_NO", label: "Noorwegen - Noors" },
	{ value: "de_AT", label: "Oostenrijk - Duits" },
	{ value: "en_PK", label: "Pakistan - Engels" },
	{ value: "es_PA", label: "Panama - Spaans" },
	{ value: "es_PY", label: "Paraguay - Spaans" },
	{ value: "es_PE", label: "Peru - Spaans" },
	{ value: "pl_PL", label: "Polen - Pools" },
	{ value: "pt_PT", label: "Portugal - Portugees" },
	{ value: "es_PR", label: "Puerto Rico - Spaans" },
	{ value: "en_RO", label: "Roemenië - Engels" },
	{ value: "ru_RU", label: "Rusland - Russisch" },
	{ value: "en_SA", label: "Saoedi-Arabië - Engels" },
	{ value: "en_SG", label: "Singapore - Engels" },
	{ value: "es_ES", label: "Spanje - Spaans" },
	{ value: "en_LK", label: "Sri Lanka - Engels" },
    { value: "en_SK", label: "Slowakije - Engels"},
	{ value: "en_SI", label: "Slovenië - Engels" },
	{ value: "zh_TW", label: "Taiwan - Traditioneel Chinees" },
	{ value: "en_TH", label: "Thaïland - Engels" },
	{ value: "th_TH", label: "Thaïland - Thaise" },
	{ value: "en_TT", label: "Trinidad en Tobago - Engels" },
	{ value: "cs_CZ", label: "Tsjechië - Tsjechisch" },
	{ value: "tr_TR", label: "Turkije - Turks" },
	{ value: "es_UY", label: "Uruguay - Spaans" },
	{ value: "en_AE", label: "Verenigde Arabische Emiraten - Engels" },
	{ value: "es_VE", label: "Venezuela - Spaans" },
	{ value: "en_VN", label: "Viëtnam - Engels" },
	{ value: "en_ZA", label: "Zuid Afrika - Engels" },
	{ value: "sv_SE", label: "Zweden - Zweeds" },
	{ value: "fr_CH", label: "Zwitserland - Frans" },
	{ value: "de_CH", label: "Zwitserland - Duits" },

]

export const primaryUse = [
  { value: "", label: "Selecteer een optie" },
	{ value: "002", label: "Privégebruik" },
	{ value: "003", label: "Kantoor aan huis" },
	{ value: "005", label: "Bedrijf met maximaal 9 werknemers" },
	{ value: "006", label: "Bedrijf met 10 of meer werknemers" },
]

export const salutation = [
  { value: "", label: "Selecteer een optie" },
	{ value: "001", label: "Dhr." },
	{ value: "002", label: "Mej." },
	{ value: "005", label: "Mevr." },
]

export const jobResponsibility = [
  { value: "", label: "Selecteer" },
	{ value: "041", label: "Administratief medewerker" },
	{ value: "030", label: "Business Professional" },
	{ value: "035", label: "CAD/CAM Professional" },
	{ value: "033", label: "Bedrijfseigenaar/CEO" },
	{ value: "055", label: "Consulent/systeemintegrator" },
	{ value: "034", label: "Afdelingshoofd" },
	{ value: "046", label: "Engineer/technisch" },
	{ value: "054", label: "Directeur/directielid" },
	{ value: "036", label: "Faciliteitenmanager" },
	{ value: "047", label: "Financieel/accountingprofessional" },
	{ value: "031", label: "Hoofd financiën/CFO" },
	{ value: "037", label: "Grafisch professional" },
	{ value: "032", label: "IT-manager (CIO/CTO)" },
	{ value: "051", label: "IT-strateeg" },
	{ value: "049", label: "Marketingprofessional" },
	{ value: "045", label: "Manager IT-groep/afdeling" },
	{ value: "044", label: "MIS-/IT-professional" },
	{ value: "042", label: "Bureauchef" },
	{ value: "043", label: "Inkoopmanager" },
	{ value: "050", label: "Verkoopprofessional" },
	{ value: "040", label: "Service/Support/Helpdesk" },
	{ value: "048", label: "Software-ontwikkelaar" },
	{ value: "052", label: "Rector/hoofdonderwijzer" },
	{ value: "053", label: "Docent" },
	{ value: "000", label: "Andere" },
]
