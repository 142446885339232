export const salutation = [
  { value: "", label: "Seleccione uma" },
	{ value: "001", label: "Sr." },
	{ value: "003", label: "Sra." },
];

export const jobResponsibility = [
  { value: "", label: "Seleccione uma opção" },
	{ value: "041", label: "Assistente administrativo" },
	{ value: "030", label: "Profissional de negócios" },
	{ value: "035", label: "Profissional de CAD/CAM" },
	{ value: "033", label: "Proprietário de empresa/CEO" },
	{ value: "055", label: "Consultor/Integrador de sistemas" },
	{ value: "034", label: "Chefe de departamento" },
	{ value: "046", label: "Engenheiro/Técnico" },
	{ value: "054", label: "Administrador/Director executivo" },
	{ value: "036", label: "Gestor de recursos" },
	{ value: "047", label: "Profissional de finanças/Contabilista" },
	{ value: "031", label: "Director financeiro/CFO" },
	{ value: "037", label: "Profissional de artes gráficas" },
	{ value: "032", label: "Executivo de TI (CIO/CTO)" },
	{ value: "051", label: "Estratega de TI" },
	{ value: "049", label: "Profissional de marketing" },
	{ value: "045", label: "Gestor de sistemas de informação de gestão/grupo de TI/departamento" },
	{ value: "044", label: "Profissional de sistemas de informação de gestão/TI" },
	{ value: "042", label: "Chefe de departamento" },
	{ value: "043", label: "Profissional de aquisições/política de aquisições" },
	{ value: "050", label: "Profissional de vendas" },
	{ value: "040", label: "Serviço/Suporte/Help Desk" },
	{ value: "048", label: "Técnico de desenvolvimento de software" },
	{ value: "052", label: "Chefe/Director" },
	{ value: "053", label: "Professor" },
	{ value: "000", label: "Outro" },
]

export const country_region = [
	{ value: "en_US", label: "Estados Unidos - Inglês" },
	{ value: "en_ZA", label: "África do Sul - Inglês" },
	{ value: "de_DE", label: "Alemanha - Alemão" },
	{ value: "en_SA", label: "Arábia Saudita - Inglês" },
	{ value: "es_AR", label: "Argentina - Espanhol" },
	{ value: "en_AW", label: "Aruba - Inglês" },
	{ value: "en_AU", label: "Austrália - Inglês" },
	{ value: "de_AT", label: "Áustria - Alemão" },
	{ value: "en_BS", label: "Bahamas - Inglês" },
	{ value: "en_BD", label: "Bangladesh - Inglês" },
	{ value: "en_BB", label: "Barbados - Inglês" },
	{ value: "nl_BE", label: "Bélgica - Holandês" },
	{ value: "fr_BE", label: "Bélgica - Francês" },
	{ value: "es_BO", label: "Bolívia - Espanhol" },
	{ value: "pt_BR", label: "Brasil - Português" },
	{ value: "en_BG", label: "Bulgária - Inglês" },
  { value: "en_CA", label: "Canadá - Inglês" },
	{ value: "fr_CA", label: "Canadá - Francês" },
	{ value: "es_CL", label: "Chile - Espanhol" },
	{ value: "zh_CN", label: "China - Chinês simplificado" },
	{ value: "es_CO", label: "Colômbia - Espanhol" },
	{ value: "ko_KR", label: "Coreia - Coreano" },
	{ value: "es_CR", label: "Costa Rica - Espanhol" },
	{ value: "da_DK", label: "Dinamarca - Dinamarquês" },
  { value: "es_EC", label: "Equador - Espanhol" },
	{ value: "en_EG", label: "Egipto - Inglês" },
	{ value: "es_SV", label: "El Salvador - Espanhol" },
	{ value: "en_AE", label: "Emirados Árabes Unidos - Inglês" },
    { value: "en_SK", label: "Eslováquia - Inglês"},
	{ value: "en_SI", label: "Eslovenia - Inglês" },
	{ value: "es_ES", label: "Espanha - Espanhol" },
    { value: "en_EE", label: "Estônia - Inglês"},
	{ value: "ru_RU", label: "Federação Russa - Russo" },
	{ value: "fi_FI", label: "Finlândia - Finlandês" },
	{ value: "en_PH", label: "Filipinas - Inglês" },
	{ value: "fr_FR", label: "França - Francês" },
	{ value: "el_GR", label: "Grécia - Grego" },
	{ value: "en_GD", label: "Granada - Inglês" },
	{ value: "es_GT", label: "Guatemala - Espanhol" },
	{ value: "nl_NL", label: "Holanda - Holandês" },
  { value: "es_HN", label: "Honduras - Espanhol" },
	{ value: "en_HK", label: "Hong Kong - Inglês" },
	{ value: "zh_HK", label: "Hong Kong - Chinês tradicional" },
	{ value: "hu_HU", label: "Hungria-Húngaro" },
	{ value: "en_IN", label: "Índia - Inglês" },
	{ value: "en_ID", label: "Indonésia - Inglês" },
	{ value: "in_ID", label: "Indonésia - Indonésio" },
	{ value: "en_IE", label: "Irlanda - Inglês" },
	{ value: "en_IL", label: "Israel - Inglês" },
	{ value: "it_IT", label: "Itália - Italiano" },
  { value: "en_JM", label: "Jamaica - Inglês" },
	{ value: "ja_JP", label: "Japão - Japonês" },
	{ value: "en_KW", label: "Kuwait - Inglês" },
    { value: "en_LV", label: "Letônia - Inglês"},
	{ value: "en_LB", label: "Líbano - Inglês" },
    { value: "en_LT", label: "Lituânia - Inglês"},
	{ value: "fr_LU", label: "Luxemburgo - Francês" },
  { value: "en_MY", label: "Malásia - Inglês" },
	{ value: "es_MX", label: "México - Espanhol" },
	{ value: "en_MA", label: "Marrocos - Inglês" },
	{ value: "en_NZ", label: "Nova Zelândia - Inglês" },
	{ value: "es_NI", label: "Nicarágua - Espanhol" },
	{ value: "no_NO", label: "Noruega - Norueguês" },
  { value: "en_PK", label: "Paquistão - Inglês" },
	{ value: "es_PA", label: "Panamá - Espanhol" },
	{ value: "es_PY", label: "Paraguai - Espanhol" },
	{ value: "es_PE", label: "Peru - Espanhol" },
	{ value: "pl_PL", label: "Polónia - Polaco" },
	{ value: "pt_PT", label: "Portugal - Português" },
	{ value: "es_PR", label: "Porto Rico - Espanhol" },
	{ value: "en_GB", label: "Reino Unido - Inglês" },
	{ value: "cs_CZ", label: "República Checa - Checo" },
	{ value: "es_DO", label: "República Dominicana - Espanhol" },
  	{ value: "en_RO", label: "Romênia - Inglês" },
	{ value: "en_SG", label: "Singapura - Inglês" },
	{ value: "en_LK", label: "Sri Lanka - Inglês" },
	{ value: "sv_SE", label: "Suécia - Sueco" },
	{ value: "fr_CH", label: "Suíça - Francês" },
	{ value: "de_CH", label: "Suíça - Alemão" },
  { value: "zh_TW", label: "Taiwan - Chinês tradicional" },
	{ value: "en_TH", label: "Tailândia - Inglês" },
	{ value: "th_TH", label: "Tailândia - Tailandês" },
	{ value: "en_TT", label: "Trinidad e Tobago - Inglês" },
	{ value: "tr_TR", label: "Turquia - Turco" },
	{ value: "es_UY", label: "Uruguai - Espanhol" },
	{ value: "es_VE", label: "Venezuela - Espanhol" },
	{ value: "en_VN", label: "Vietname - Inglês" },
];

export const primaryUse = [
  { value: "", label: "Seleccione uma" },
	{ value: "002", label: "Utilização pessoal" },
	{ value: "003", label: "Empresa doméstica" },
	{ value: "005", label: "Empresa com 9 ou menos funcionários" },
	{ value: "006", label: "Empresa com 10 ou mais funcionários" },
];
