export const country_region = [
	{ value: "en_US", label: "Ηνωμένες Πολιτείες - Αγγλικά" },
  { value: "es_AR", label: "Αργεντινή - Ισπανικά" },
	{ value: "en_AW", label: "Αρούμπα - Αγγλικά" },
	{ value: "en_AU", label: "Αυστραλία - Αγγλικά" },
	{ value: "de_AT", label: "Αυστρία - Γερμανικά" },
	{ value: "en_BS", label: "Μπαχάμες - Αγγλικά" },
	{ value: "en_BD", label: "Το Μπαγκλαντές - Αγγλικά" },
	{ value: "en_BB", label: "Μπαρμπάντος - Αγγλικά" },
	{ value: "nl_BE", label: "Βέλγιο - Ολλανδικά" },
	{ value: "fr_BE", label: "Βέλγιο - Γαλλικά" },
	{ value: "es_BO", label: "Βολιβία - Ισπανικά" },
	{ value: "pt_BR", label: "Βραζιλία - Πορτογαλικά" },
	{ value: "en_BG", label: "Βουλγαρία - Αγγλικά" },
	{ value: "en_CA", label: "Καναδάς - Αγγλικά" },
	{ value: "fr_CA", label: "Καναδάς - Γαλλικά" },
	{ value: "es_CL", label: "Χιλή - Ισπανικά" },
	{ value: "zh_CN", label: "Κίνα - Απλοποιημένα Κινέζικα" },
	{ value: "es_CO", label: "Κολομβία - Ισπανικά" },
	{ value: "es_CR", label: "Κόστα Ρίκα - Ισπανικά" },
	{ value: "cs_CZ", label: "Δημοκρατία της Τσεχίας - Τσεχικά" },
	{ value: "da_DK", label: "Δανία - Δανικά" },
	{ value: "es_DO", label: "Δομινικανή Δημοκρατία - Ισπανικά" },
	{ value: "es_EC", label: "Ισημερινός - Ισπανικά" },
	{ value: "en_EG", label: "Αίγυπτος - Αγγλικά" },
	{ value: "es_SV", label: "Ελ Σαλβαδόρ - Ισπανικά" },
    { value: "en_EE", label: "Εσθονία - Αγγλικά"},
	{ value: "fi_FI", label: "Φινλανδία - Φινλανδικά" },
	{ value: "fr_FR", label: "Γαλλία - Γαλλικά" },
	{ value: "de_DE", label: "Γερμανία - Γερμανικά" },
	{ value: "el_GR", label: "Ελλάδα - Ελληνικά" },
	{ value: "en_GD", label: "Γρενάδα - Αγγλικά" },
	{ value: "es_GT", label: "Γουατεμάλα - Ισπανικά" },
	{ value: "es_HN", label: "Ονδούρα - Ισπανικά" },
	{ value: "en_HK", label: "Χονκ Κονγκ - Αγγλικά" },
	{ value: "zh_HK", label: "Χονγκ Κόνγκ - Παραδοσιακά Κινεζικά" },
	{ value: "hu_HU", label: "Ουγγαρία - Ουγγρικά" },
	{ value: "en_IN", label: "Ινδία - Αγγλικά" },
	{ value: "en_ID", label: "Ινδονησία - Αγγλικά" },
	{ value: "in_ID", label: "Ινδονησία - Ινδονησίας" },
	{ value: "en_IE", label: "Ιρλανδία - Αγγλικά" },
	{ value: "en_IL", label: "Ισραήλ - Αγγλικά" },
	{ value: "it_IT", label: "Ιταλία - Ιταλικά" },
	{ value: "en_JM", label: "Τζαμάικα - Αγγλικά" },
	{ value: "ja_JP", label: "Ιαπωνία - Ιαπωνικά" },
	{ value: "ko_KR", label: "Κορέα - Κορεατικά" },
	{ value: "en_KW", label: "Κουβέιτ - Αγγλικά" },
    { value: "en_LV", label: "Λετονία - Αγγλικά"},
	{ value: "en_LB", label: "Λίβανος-Αγγλικά" },
    { value: "en_LT", label: "Λιθουανία - Αγγλικά"},
	{ value: "fr_LU", label: "Λουξεμβούργο - Γαλλικά" },
	{ value: "en_MY", label: "Μαλαισία - Αγγλικά" },
	{ value: "es_MX", label: "Μεξικό - Ισπανικά" },
	{ value: "en_MA", label: "Μαρόκο - Αγγλικά" },
	{ value: "nl_NL", label: "Ολλανδία - Ολλανδικά" },
	{ value: "en_NZ", label: "Νέα Ζηλανδία - Αγγλικά" },
	{ value: "es_NI", label: "Νικαράγουα - Ισπανικά" },
	{ value: "no_NO", label: "Νορβηγία - Νορβηγικά" },
	{ value: "en_PK", label: "Πακιστάν - Αγγλικά" },
	{ value: "es_PA", label: "Παναμάς - Ισπανικά" },
	{ value: "es_PY", label: "Παραγουάη - Ισπανικά" },
	{ value: "es_PE", label: "Περού - Ισπανικά" },
	{ value: "en_PH", label: "Φιλιππίνες - Αγγλικά" },
	{ value: "pl_PL", label: "Πολωνία - Πολωνικά" },
	{ value: "pt_PT", label: "Πορτογαλία - Πορτογαλικά" },
	{ value: "es_PR", label: "Πουέρτο Ρίκο - Ισπανικά" },
	{ value: "en_RO", label: "Ρουμανία - Αγγλικά" },
	{ value: "ru_RU", label: "Ρωσία - Ρωσικά" },
	{ value: "en_SA", label: "Σαουδική Αραβία - Αγγλικά" },
	{ value: "en_SG", label: "Σιγκαπούρη - Αγγλικά" },
	{ value: "es_ES", label: "Ισπανία - Ισπανικά" },
	{ value: "en_LK", label: "Σρι Λάνκα - Αγγλικά" },
	{ value: "en_ZA", label: "Νότια Αφρική - Αγγλικά" },
	{ value: "en_SI", label: "Σλοβενία - Αγγλικά" },
	{ value: "sv_SE", label: "Σουηδία - Σουηδικά" },
	{ value: "fr_CH", label: "Ελβετία - Γαλλικά" },
	{ value: "de_CH", label: "Ελβετία - Γερμανικά" },
	{ value: "zh_TW", label: "Ταϊβάν - Παραδοσιακά Κινέζικα" },
	{ value: "en_TH", label: "Ταϊλάνδη - Αγγλικά" },
	{ value: "th_TH", label: "Ταϊλάνδη - Ταϊλάνδης" },
	{ value: "en_TT", label: "Τρινιδάδ και Τομπάγκο - Αγγλικά" },
	{ value: "tr_TR", label: "Τουρκία - Τουρκικά" },
	{ value: "en_AE", label: "Ηνωμένα Αραβικά Εμιράτα" },
	{ value: "en_GB", label: "Ηνωμένο Βασίλειο - Αγγλικά" },
	{ value: "es_UY", label: "Ουρουγουάη - Ισπανικά" },
	{ value: "es_VE", label: "Βενεζουέλα - Ισπανικά" },
	{ value: "en_VN", label: "Βειτνάμ - Αγγλικά" },
    { value: "en_SK", label: "Σλοβακία - Αγγλικά"},

]

export const primaryUse = [
  { value: "", label: "Επιλέξτε έναν" },
	{ value: "002", label: "Προσωπική χρήση" },
	{ value: "003", label: "Επιχείρηση με βάση το σπίτι" },
	{ value: "005", label: "Επιχείρηση με 9 ή λιγότερους υπαλλήλους" },
	{ value: "006", label: "Επιχείρηση με 10 ή περισσότερους υπαλλήλους" },
]

export const salutation = [
  { value: "", label: "Επιλέξτε έναν" },
	{ value: "001", label: "Κος" },
	{ value: "002", label: "Κα." },
	{ value: "005", label: "Δις" },
]

export const jobResponsibility = [
  { value: "", label: "Επιλέξτε ένα στοιχείο" },
	{ value: "041", label: "Διοικητικός βοηθός" },
	{ value: "030", label: "Ελεύθερος επαγγελματίας" },
	{ value: "035", label: "Επαγγελματίας CAD/CAM" },
	{ value: "033", label: "Ιδιοκτήτης/Γενικός διευθυντής εταιρείας" },
	{ value: "055", label: "Σύμβουλος/Integrator συστημάτων" },
	{ value: "034", label: "Επικεφαλής τμήματος" },
	{ value: "046", label: "Μηχανικός/Τεχνικός" },
	{ value: "054", label: "Στέλεχος/Μέλος ΔΣ" },
	{ value: "036", label: "Υπεύθυνος εγκαταστάσεων" },
	{ value: "047", label: "Επαγγελματίας οικονομικών/λογιστικών" },
	{ value: "031", label: "Οικονομικό στέλεχος/διευθυντής" },
	{ value: "037", label: "Επαγγελματίας γραφικών τεχνών" },
	{ value: "032", label: "Στέλεχος τμήματος πληροφορικής (CIO/CTO)" },
	{ value: "051", label: "Υπεύθυνος στρατηγικής πληροφορικής" },
	{ value: "049", label: "Επαγγελματίας μάρκετινγκ" },
	{ value: "045", label: "Υπεύθυνος τμήματος πληροφορικής" },
	{ value: "044", label: "Επαγγελματίας MIS/IT" },
	{ value: "042", label: "Υπεύθυνος γραφείου" },
	{ value: "043", label: "Επαγγελματίας στον τομέα των αγορών" },
	{ value: "050", label: "Επαγγελματίας πωλήσεων" },
	{ value: "040", label: "Σέρβις/Υποστήριξη/Τηλ. κέντρο βοήθειας" },
	{ value: "048", label: "Προγραμματιστής" },
	{ value: "052", label: "Επιθεωρητής/Διευθυντής σχολείου" },
	{ value: "053", label: "Δάσκαλος" },
	{ value: "000", label: "Άλλα" },
]
